import React, { FC } from "react"
import Layout from "../components/layout"
import { PageProps } from "gatsby"

const NotFoundPage: FC<PageProps> = () => (
  <Layout>
    <h1>404 - NOT FOUND</h1>
    <p>The page you are looking for doesn&#39;t exist.</p>
  </Layout>
)

export default NotFoundPage
